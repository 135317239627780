import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hello, my name is Brandon Orther.
    </Text>
    <p>{`I'm a product-oriented engineer living in San Diego, CA.`}</p>
    <p>{`I run `}<a parentName="p" {...{
        "href": "https://uptrend.tech"
      }}>{`Uptrend`}</a>{` – a software development company that partners with non-technical founders to build digital products.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      